<template>
  <v-container fluid>
    <app-header>
      <v-btn text @click="$router.back()">
        <v-icon>{{ 'mdi-arrow-left' }}</v-icon>
        Back
      </v-btn>
    </app-header>
    <v-card tile elevation="0">
      <v-card-title>
        {{ $t('mainLayout.recentDownloads') }}
      </v-card-title>
    </v-card>
    <v-simple-table
        fixed-header
    >
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-center" id="downloads-name">
            {{ $t('recentDownloads.name') }}
          </th>
          <th class="text-center" id="downloads-date">
            {{ $t('recentDownloads.date') }}
          </th>
          <th class="text-center" id="downloads-link">
            {{ $t('recentDownloads.link') }}
          </th>
          <th id="downloads-blank"></th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="item in getDeepLinks"
            :key="item.name + item.dateCreated"
        >
          <td class="text-center">{{ item.fileName }}</td>
          <td class="text-center">{{ new Date(item.dateCreated).toString() }}</td>
          <td class="text-center">
            <v-btn text tile outlined :href="item.link"
                   class="mr-10"
            >
              {{ $t('project.header.download.title') }}
            </v-btn>
            <v-btn text tile outlined @click="copyLink(item.link)">
              {{ $t('recentDownloads.copyLink') }}
            </v-btn>
          </td>
          <td>
            <v-btn icon
                   @click="deleteItem(item.id)">
              <v-icon>
                {{ 'mdi-delete' }}
              </v-icon>
            </v-btn>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-container>
</template>

<script>
import AppHeader from "@/components/AppHeader";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Downloads",
  props: ['id'],
  components: {AppHeader},
  data() {
    return {
      items: []
    }
  },
  /*
  async created() {
    await this.getDownloads(this.id)
  },
   */
  async mounted() {
    await this.getDownloads(this.id)
  },
  methods: {
    ...mapActions({
      getDownloads: 'main/getDownloads',
      removeDownloadLink: 'main/removeDownloadLink'
    }),
    copyLink(link) {
      const a = document.createElement("input");
      a.setAttribute('id', 'link')
      a.value = link;
      document.body.appendChild(a);
      let b = document.getElementById('link')
      b.select();
      b.setSelectionRange(0, 99999);
      document.execCommand("copy");
      document.body.removeChild(a);
    },
    deleteItem(id) {
      this.removeDownloadLink({id, projectId: this.id})
    }
  },
  computed: {
    ...mapGetters({
      getDeepLinks: 'main/getDeepLinks'
    })
  }
}
</script>

<style scoped lang="sass">
.v-btn:hover:before
  color: #183857

.mdi-delete:hover:before
  color: #183857

.mdi-delete:focus:before
  color: gray

.v-btn:focus:before
  color: gray
</style>